<template>
  <FocusTrap>
    <div>
      <div class="card" id="mycard">

        <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
          <div class="form-group row">
            <div class="col">
              <h5>Settlement View</h5>
            </div>

            <label class="col-md-1 col-form-label font-weight-semibold">Cashier</label>
            <div class="col-md-1">
              <select id="cmbcashier" class="form-control" v-model="cashier" @change="cashierChanged" autofocus>
                <option value="0" selected >-</option>
                <option v-for="user in cashiers" v-bind:value="user.id" >  {{user.name}}</option>
              </select>
            </div>



            <label class="col-md-1 col-form-label font-weight-semibold">Date</label>
            <div class="col-md-2">
              <input class="form-control" type="date"  v-model="rep_date"  @change="loadSessions" >
            </div>

            <label class="col-md-1 col-form-label font-weight-semibold">Settlement</label>
            <div class="col-md-2">
              <select class="form-control" v-model="session">
                <option v-for="user in sessions" v-bind:value="user" >  {{user}}</option>
              </select>
            </div>

            <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadSaleData" >Show</button>
          </div>

        </div>

        <div class="table-responsive">
          <table id="bill_table"
                 class="table table-bordered table-columned"
                 data-search="false"
                 data-pagination="false"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-row-style="rowStyle"
                 data-detail-view="true"
                 data-detail-view-icon="false"
                 data-detail-view-by-click="true"
                 data-detail-formatter="billDetailFormatter"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-field="id" data-class="d-none" data-sortable="true">ID</th>
              <th data-formatter="runningFormatter" data-width="50" >#</th>
              <th data-field="doc_no" data-width="75" data-sortable="true"  >Bill No</th>
              <th data-field="bill_date" data-width="109" data-sortable="true" data-formatter="dateFormatter">Date</th>
              <th data-field="bill_date" data-width="50" data-sortable="true" data-formatter="timeFormatter">Time</th>
              <th data-field="bill_type" data-width="75" data-sortable="true" >Type</th>
              <th data-field="counter.name" data-width="125" data-sortable="true" >Counter</th>
              <th data-field="customer_name" data-sortable="true" >Customer</th>
              <th data-field="vehicle_no" data-width="135" data-sortable="true" >Vehicle</th>
              <th data-field="netamt" data-width="111" data-sortable="true" data-align="right" >Amount</th>
              <th data-field="maker" data-width="109" data-sortable="true" data-class="d-none">Cashier</th>
              <th data-field="terminal.name" data-width="109" data-sortable="true" >Device</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>


        <!-- Context Menu -->
        <div id="context-menu" class="context-table">
          <div class="dropdown-menu">
            <a href="#" class="dropdown-item" ><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
  <!--          <div class="dropdown-divider"></div>-->
  <!--          <a href="#" class="dropdown-item"> <i class="icon-calculator4" ></i>Upload to Account</a>-->
          </div>
        </div>
        <!-- End of Context Menu -->

      </div>

      <div >
        <div class="mb-3 pt-2">
          <!--        <h6 class="mb-0 font-weight-semibold">-->
          <!--          Card navigation options-->
          <!--        </h6>-->
          <span class="text-muted d-block;">Coupon Sale Summary</span>
        </div>

        <div class="row" >

          <div class="col-md-12">


              <div >
                <div class="table-responsive">
                  <table   class="table table-xs table-columned table-hover" data-target=".context-table">
                    <thead>
                      <tr>
                        <th style="width: 50px;">S.No</th>
                        <th>Item</th>
                        <th style="width: 150px;text-align: right">Qty</th>
                        <th style="width: 150px;text-align: right">Amount</th>
                      </tr>
                    </thead>
                    <tbody v-if="tokensummary"  >
                      <tr scope="row"  v-for="(trn,index) in tokensummary">
                        <td>{{index + 1}}</td>
                        <td> {{trn.name}} </td>
                        <td style="width: 150px;text-align: right">{{trn.qty | indianFormat}}</td>
                        <td style="width: 150px;text-align: right">{{trn.netamt | indianFormat}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr >
                        <td></td>
                        <td style="text-align: right;"> </td>
                        <td style="width: 150px;text-align: right;"> <div class="font-weight-semibold">Total</div> </td>
                        <td style="width: 150px;text-align: right;"> <div class="font-weight-semibold">{{ total_value | indianFormat}} </div></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

              </div>


          </div>


        </div>

      </div>

      <FocusTrap>
        <vmodal name="bill-detail" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="225" >
        <form>
          <div class="card">
            <div class="card-header header-elements-inline" style="background-color: darkgrey;color: white;padding: 10px;">
              <h3 class="card-title">Bill Detail</h3>
              <div class="header-elements">
                <div class="list-icons">
                  <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                </div>
              </div>
            </div>

            <div class="card-body">


            </div>
          </div>
        </form>
      </vmodal>
      </FocusTrap>
    </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'BillView',
    data(){
      return {
        cashier:0,
        cashiers:[],
        billdata:[],
        sessions:[],
        tokensale:  new Map(),
        tokensummary: [],
        session:'',
        total_value: 0,
        total_qty: 0,
        rep_date:  '',// moment().format('YYYY-MM-DD'),
      }
    },
    created(){
      //https://devbridge.github.io/jQuery-Autocomplete/scripts/countries.js


    },
    mounted () {
      const self = this;

      this.$data.mytable = $('#bill_table');
      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          let code = $(row.children('*')[1]).text();

          if ($(e.target).text() === 'Refresh') {
            self.loadSaleData();
          } else if ($(e.target).text() === 'Upload to Account'  && code.length > 0 ) {
            self.exportVoucher(code);
          }
        }
      })

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#buyer-table>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadCashiers();

      $('#cmbcashier').focus();

    },
    filters:{
      billtype(val){
        switch (val) {
          case 1:
            return 'Cash';
          case 2:
            return 'Credit';
          case 3:
            return 'Card';
          case 4:
            return 'Fleet';
          case 5:
            return 'Suspense';
        }
      },
      tokonFormat(val){
        if(true)
          return "Coupon";
        return "Bill";
      },
      indianFormat(val){
        return val.toFixed(2);
      },
      dateFormat(val){
        try {
          return val.slice(0, 10).split('-').reverse().join('-');
        } catch (ex) {

        }
        return '';
      },
    },
    methods:{
      cashierChanged(){
        this.$data.rep_date ='';
        this.$data.sessions = [];
        this.$data.billdata = [];
        this.$data.total_value = 0;
        this.$data.billdata = [];
        this.$data.tokensummary = [];
        this.$data.tokensale = new Map();
        this.$data.mytable.bootstrapTable('load', []);
      },
      dateChanged(){
        this.$data.sessions = [];
        this.$data.billdata = [];
        this.$data.total_value = 0;
        this.$data.billdata = [];
        this.$data.tokensummary = [];
        this.$data.tokensale = new Map();
        this.$data.mytable.bootstrapTable('load', []);
      },
      loadSaleData(){

        if (this.$data.cashier < 0){
          swal({type: "error", title: "Oops...", text: 'Invalid Cashier'});
          return;
        }

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let query = 'from=' + self.$data.from_date + '&upto=' + self.$data.upto_date + '&billtype=' + self.$data.billtype;

        self.$data.total_value = 0;
        self.$data.billdata = [];
        self.$data.tokensummary = [];
        self.$data.tokensale = new Map();

        fetch(`${process.env.VUE_APP_ROOT_API}api/v1/pos/user/coupon/${self.$data.session}/`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {

              if (_.isArray(resp.data)) {

                self.$data.mytable.bootstrapTable('load', resp.data);

                self.$data.total_value = 0;
                resp.data.forEach(function (bill) {
                  // self.$data.total_value += bill.netamt;


                  if(bill.status == 0 && bill.istoken){

                    self.$data.total_value += bill.netamt;

                    bill.list.forEach(function (detail) {

                      if(self.$data.tokensale.has(detail.item.name)) {

                        let dat = self.$data.tokensale.get(detail.item.name);

                        dat.qty += detail.qty;
                        dat.netamt += detail.netamt;

                        self.$data.tokensale.set(detail.item.name, dat );
                      }else{
                        self.$data.tokensale.set(detail.item.name, {'name': detail.item.name, 'qty': detail.qty, 'netamt': detail.netamt });
                      }

                    });

                  }

                });

                for (let key of self.$data.tokensale.keys()) {
                  self.$data.tokensummary.push( self.$data.tokensale.get(key));
                }
              }


          } else {
            swal({ title: 'Oops!', text: err.msg, type: 'error' });
          }

        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
        }).finally(function () {
          $('#mycard').unblock();
        });
      },
      loadSessions () {

        const self = this;
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.sessions = [];
        self.$data.billdata = [];

        self.$data.total_value = 0;
        self.$data.billdata = [];
        self.$data.tokensummary = [];
        self.$data.tokensale = new Map();
        self.$data.mytable.bootstrapTable('load', self.$data.billdata);


        fetch(`${process.env.VUE_APP_ROOT_API}api/v1/pos/user/settlements/${self.$data.cashier}/?rep_date=${self.$data.rep_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sessions = resp.data;
            }
            self.$data.sessions.push('pos_' + self.$data.cashier);
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      },
      loadCashiers () {
        const self = this;
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }


        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.cashiers = [];

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashiers`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.cashiers = resp.data;
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      },
      exportVoucher (code) {
        const self = this;

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/v1/pos/bill/export/${code}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({title: 'Exported', type: 'success', text: resp.msg });
            // self.$emit('contra_saved',resp.data);
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' })
        });
      },
    }

  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
